import React from "react";
import {Secondary} from "../../components/layouts/Secondary/Secondary";
import styled from "styled-components";

const Wrapper = styled.section`
  padding: 100px 11.32%;
  main {
    ul {
      padding: 10px 21px;
      li {
        list-style: disc;
      }
    }
    li {
      list-style: auto;
      margin-top: 15px;
      font-size: 1.25rem;
      line-height: 34px;
    }
    .list-style-none li {
      list-style: none;
    }
    .padding-none {
      padding: 0;
    }
    .margin-none {
      margin: 0;
    }
    .ul-li-margin-none {
      li {
        margin: 0;
      }
    }
    .line-height-normal {
      line-height: normal;
    }
    ol.list-style-lower-roman li {
      list-style: lower-roman;
    }
    h2 {
      margin-top: 30px;
      font-size: 1.6rem;
    }
    p {
      margin-top: 10px;
    }
    .ol-left-padding {
      padding-left: 90px;
    }
    .underline-text {
      text-decoration: underline;
    }
  }
`;

const TermsAndConditions = () => (
  <Secondary>
    <Wrapper>
      <main>
        <h2 className="last-updated">Last updated: November 7, 2022</h2>
        <p>
          Flahmingo Investments Inc. (“Flahmingo”, "we" or "us") and our
          representatives may have interests that are inconsistent with your
          interests. This may give rise to an actual or perceived risk that we
          favour our interests over yours as our customer.
        </p>
        <p>
          We act fairly, honestly and in good faith with you and our other
          customers. To this end, we avoid material conflicts of interest that
          we cannot effectively address and conflicts that are prohibited by
          law.
        </p>
        <p>
          We have adopted policies and procedures to identify and address all
          remaining material conflicts in your best interests, including:
        </p>
        <ul>
          <li>Conflicts from relationships with third party vendors</li>
          <li>
            Conflicts arising out of competing interests among our customers
          </li>
          <li>
            Conflicts caused by compensation practices and incentive programs
          </li>
          <li>Conflicts due to our Flahmingo personnels’ personal interests</li>
          <li>Conflicts related to referral arrangements with our partners</li>
        </ul>
        <p>
          Further details on each of the conflicts summarized above disclosed to
          you in this Conflict of Interest Statement. Other actual and potential
          material conflicts of interest, if not avoided, will be disclosed to
          you as they arise. Our personnel are also subject to the Flahmingo
          Code of Ethics as part of our policies and procedures.
        </p>
        <h2>1. Conflicts from relationships with third party vendors</h2>
        <p>
          Routing trading to third parties: Flahmingo engages the execution
          services of Alpaca Securities LLC ("Alpaca"), a broker/dealer
          registered in the United States for U.S. listed equities and options.
        </p>
        <p>
          Alpaca routes to the following market makers for execution (this is
          not an exhaustive list, additional approved venues may be used):
        </p>
        <ul>
          <li>
            Virtu Americas, LLC (NITE) - Orders in US exchange issues and OTC
            issues are routed to NITE
          </li>
          <li>
            Citadel Securities LLC (CDRG) - Orders in US exchange issues and OTC
            issues are routed to CDRG
          </li>
          <li>
            Jane Street Capital, LLC (JNST) – Only orders in US exchange issues
            are routed to JNST. JNST is planning to offer OTC issues in the
            future.
          </li>
          <li>
            UBS Securities (UBSS) - Only orders in US exchange issues are routed
            to UBSS
          </li>
          <li>
            MCAP LLC (MCAP) - Orders in OTC ADRs are routed to MCAP are planned
            for the future. This firm specializes in foreign securities.
          </li>
        </ul>
        <p>
          Payment for order flow: Alpaca receives revenue from third party
          liquidity providers based on the order flow execution received at each
          destination. While rebate rates are substantially similar amongst the
          liquidity providers they may vary based on order characteristics (such
          as price of execution, marketability and other characteristics).
          Alpaca does not negotiate with venues over rates as a condition of a
          venue receiving its order flow.
        </p>
        <p>
          There is a potential conflict inherent to a market maker such as those
          listed above both paying for order flow and providing price
          improvement, as the potential source of funds for each is the same,
          namely the anticipated profit the market maker seeks to earn from
          executing or facilitating the execution of Alpaca/Flahmingo customer
          orders. Accordingly, from such anticipated profit, a market maker such
          as those listed above can (i) forgo a portion of such anticipated
          profit to provide price improvement; (ii) forgo a portion of such
          anticipated profit to pay for order flow; or (iii) retain a larger
          portion of anticipated profit and not provide (or provide less) price
          improvement or not provide (or provide less) payment for order flow. A
          market maker’s anticipated profit must be allocated among these three
          sub-categories, such that an increased allocation to any one
          subcategory will result in a decreased allocation to one or more of
          the other categories.
        </p>
        <p>
          <strong>
            For clarity, Flahmingo does not receive any payment directly or
            indirectly from market makers in return for routing customer orders
            in U.S. equities pursuant to order routing arrangements.
          </strong>
        </p>
        <p>
          Alpaca files quarterly reports with the U.S. Securities and Exchange
          Commission (SEC) as required of all brokerage firms to make publicly
          these reports, broken down by calendar month, containing certain
          required statistical information regarding the routing of held,
          non-directed customer orders in Regulation National Market System
          stocks and listed options. For each section, the report identifies the
          venues to which Alpaca routed the relevant orders and, for each venue,
          the required statistical information broken down by order type (i.e.,
          market order, marketable limit order, non-marketable limit order and
          other orders). Each section of the report also contains information
          regarding the material aspects of Alpaca's relationship, if any, with
          each venue.
        </p>
        <h2>
          2. Conflicts arising out of competing interests among our customers
        </h2>
        <p>
          Flahmingo will make reasonable efforts to achieve best execution by
          overseeing the best execution of customers’ orders executed by Alpaca.
          This includes a review of Alpaca’s publicly available customer
          disclosure and order routing practices, and obtaining confirmation
          from them that they have complied with and tested their best execution
          policies and procedures.
        </p>
        <p>
          Best Execution: Best execution means obtaining the most advantageous
          execution terms for a customer order that are reasonably available
          under the circumstances. Flahmingo is obligated to establish policies
          and procedures that are designed to achieve best execution when acting
          for a customer. These policies and procedures must take into
          consideration factors such as price, speed of execution, certainty of
          execution and the overall cost of the transaction, when costs are
          passed on to customers.
        </p>
        <p>
          It can occur that orders entered by more than one customer at the same
          time are filled at different prices and at different speeds.
        </p>
        <h2>
          3. Conflicts caused by compensation practices and incentive programs
        </h2>
        <p>
          Revenue Earned by Personnel: Flahmingo personnel are compensated on
          the basis of salary and overall firm performance. No compensation is
          paid on the basis of sales at Flahmingo or assets held in customer
          portfolios.
        </p>
        <p>
          Revenue Earned by Flahmingo: Different products, platforms and
          add-ons, and trading in certain products or on different markets may
          have differing levels of monthly fees. Our compensation plans do not
          incent our personnel to recommend specific products, services, or
          markets including any particular type of trading platform.
        </p>
        <p>
          Flahmingo earns a spread on foreign exchange transactions you request
          in the course of funding or withdrawing funds from your account.
        </p>
        <h2>4. Conflicts due to our representatives’ personal interests</h2>
        <p>
          Outside Activities: At times, our personnel may participate in outside
          activities such as participating in community events, pursuing
          personal outside business interests or serving on a board of directors
          of a charity (with compensation). Before engaging in any outside
          activity, our policies require these individuals to disclose
          situations where a conflict of interest may arise and to determine how
          such conflicts may be addressed. Our personnel may only engage in such
          outside activities if approved by the Chief Compliance Officer under
          our policies. The approval may be subject to terms and conditions that
          help address perceived or actual conflicts of interest. Our personnel
          are also required to review, at least annually, their outside activity
          submission for accuracy and completeness.
        </p>
        <p>
          Gifts and Entertainment: Our personnel are not permitted to accept
          gifts or entertainment beyond what we consider consistent with
          reasonable business practice under our policies and with applicable
          laws. We set maximum thresholds for permitted gifts and entertainment
          to avoid any perception that the gifts or entertainment will influence
          decision-making.
        </p>
        <p>
          Personal Trading: Our policies require our personnel to act in
          accordance with applicable laws that prohibit insider trading, front
          running and similar conduct. Individuals may be required to obtain
          prior approval before making trades in their personal securities
          accounts. Our personnel are prohibited from accessing non-public
          information for their direct or indirect personal benefit.
        </p>
        <p>
          Personal Dealings with Customers: Exceptionally, our personnel may
          have additional relationships or dealings with our customers.
          Conflicts of interest can arise where a personnel has personal
          financial dealings with you, such as acquiring assets outside of your
          investing relationship, borrowing money from or lending money to you,
          or exercising control over your financial affairs.
        </p>
        <p>
          To address these conflicts, Flahmingo has policies and procedures in
          place which prohibit personal financial dealings with customers who
          are family members, other Flahmingo personnel or otherwise in a
          special relationship. These exceptions require the prior review and
          approval by the Chief Compliance Officer.
        </p>
        <h2>5. Conflicts related to referral arrangements</h2>
        <p>
          Referrals are an important part of our business. Satisfied customers
          may refer you or others to us and we may provide those customers with
          a financial incentive to do so. You may receive a CAD credit for every
          friend you refer and introduce to Flahmingo provided such person
          creates an Account, passes the know-your-customer procedures and funds
          their Account with a minimum of $25 CAD.
        </p>
        <p>
          A conflict may exist in respect of this referral arrangement given the
          financial incentive to make a referral. There is the potential to
          create a conflict between your interests and those of the person
          making the referral because it could create an incentive for that
          person to refer you to Flahmingo regardless of whether the services
          offered by Flahmingo are appropriate for you. If a referral is made to
          a registrant, all activity requiring registration resulting from the
          referral arrangement will be provided.
        </p>
        <p>
          <strong>
            You do not pay any additional charges and fees in connection with
            these referrals and you are not obligated to purchase any product or
            service in connection with a referral.
          </strong>
        </p>
      </main>
    </Wrapper>
  </Secondary>
);
export default TermsAndConditions;